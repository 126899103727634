import '../css/state-meet.css'
import React from 'react'
import Layout from '../components/layout'
import Footer from '../components/footer'
import Copyright from '../components/copyright'
import SEO from '../components/seo'
import Header from '../components/header'
import Container from '../components/container'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

const PartiesPage = () => (
  <Layout>
    <SEO
      title="State Meet 2025 | Jump Around Gymnastics"
      keywords={[
        'jump around gymnastics',
        'state meet',
        "2025 Wisconsin Men's Gymnastics State Meet",
      ]}
      description={`2025 Wisconsin Men's Gymnastics State Meet at Jump Around Gymnastics on March 22nd and 23rd, 2025.`}
      noindex
    />
    <Header>
      <div className="w-full py-16 px-2 text-center text-white text-shadow">
        <h1
          className="text-2xl xl:text-4xl text-display uppercase leading-none lg:leading-normal tracking-wide"
          style={{ textWrap: 'pretty' }}
        >
          2025 Wisconsin Men&apos;s Gymnastics State Meet
        </h1>
        <h2 className="mt-6 text-xl xl:mt-0 xl:text-2xl uppercase text-display tracking-wide">
          March 22nd &amp; 23rd, 2025
        </h2>
      </div>
    </Header>
    <Container>
      <div className="grid mx-4 my-12">
        <div className="bg-gradient-purple rounded-lg shadow-lg text-grey-lightest pb-4 px-4">
          <h1 className="font-light mb-4 text-center uppercase text-display mt-4 text-shadow">
            Location
          </h1>
          <p className="text-shadow text-center font-semibold">
            Jump Around Gymnastics
            <br />
            7129 River Rd
            <br />
            DeForest, WI 53532
          </p>
        </div>
        <div className="bg-gradient-orange rounded-lg shadow-lg text-grey-lightest pb-4 px-4">
          <h1 className="font-light mb-4 text-center uppercase text-display mt-4 text-shadow">
            Schedule
          </h1>
          <h2 className="text-xl text-shadow text-center text-display">
            Saturday March 22, 2025
          </h2>
          <p className="text-shadow text-center font-semibold">
            12:30pm - Levels 4 and 5
            <br />
            5:00pm - Levels 8, 9 and 10
            <br />
          </p>
          <h2 className="text-xl text-shadow text-center mt-8 text-display">
            Sunday March 23, 2025
          </h2>
          <p className="text-shadow text-center font-semibold">
            9:00am - Level 3
            <br />
            2:00pm - Levels 6, 7 and Technical Sequence
            <br />
          </p>
        </div>
        <div className="bg-gradient-green rounded-lg shadow-lg text-grey-lightest pb-4 px-4">
          <h1 className="font-light mb-4 text-center uppercase text-display mt-4 text-shadow">
            Entry Fees
          </h1>
          <p className="text-shadow text-center font-semibold">
            Adults - $10
            <br />
            Children (ages 5-17) - $5
          </p>
        </div>
        <div className="bg-gradient-blue rounded-lg shadow-lg text-grey-lightest pb-4 px-4">
          <h1 className="font-light mb-4 text-center uppercase text-display mt-4 text-shadow">
            Host Hotel
          </h1>
          <p className="text-shadow text-center font-semibold">
            $119/night
            <br />
            <span className="font-normal">Reservation Deal Ending 3/7/25</span>
            <br />
            <OutboundLink
              className="text-grey-lightest font-semibold text-shadow border-b border-grey-light hover:cursor-pointer"
              href="https://www.ihg.com/holidayinnexpress/hotels/us/en/find-hotels/select-roomrate?fromRedirect=true&qSrt=sBR&qIta=99801505&icdv=99801505&qSlH=DEFWI&qCiD=21&qCiMy=022025&qCoD=23&qCoMy=022025&qGrpCd=bsg&setPMCookies=true&qSHBrC=EX&qDest=7184"
            >
              Book Now!
            </OutboundLink>
          </p>
          <h2 className="text-xl text-shadow text-center text-display mt-8">
            Holiday Inn Express
          </h2>
          <p className="text-center font-semibold text-shadow">
            7184 Morrisonville Rd
            <br />
            DeForest, WI 53532
          </p>
        </div>
      </div>
      <div className="my-12 mx-4 xl:mx-auto xl:w-1/2 bg-gradient-red rounded-lg shadow-lg text-grey-lightest p-4">
        <h1 className="font-light mb-4 text-center uppercase text-display mt-4 text-shadow">
          Apparel Pre-Order
        </h1>
        <p className="text-center pb-8">
          <OutboundLink
            className="text-grey-lightest font-semibold text-shadow border-b border-grey-light hover:cursor-pointer"
            href="https://www.altiussport.com/state-meet"
          >
            Pre-order your 2025 State Meet apparel now!
          </OutboundLink>
        </p>
      </div>
    </Container>
    <Footer></Footer>
    <Copyright></Copyright>
  </Layout>
)

export default PartiesPage
